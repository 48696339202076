import React from "react";
import "./index.scss";
import { renderCurrency } from "../../../../constants";
import { _t } from "../../../../../Common/components/InjectIntlContext";
import classNames from "classnames";

type Type = {
  assured_income: number;
  rs_income: number;
  partner_income: number;
  tableDetails?: boolean;
};
const CellPartnerIncome: React.FC<Type> = ({
  assured_income,
  rs_income,
  partner_income,
  tableDetails,
}) => {

  return (
    <div
      className={classNames("cell-partner-income", {
        "cell-partner-income--details": tableDetails,
      })}
    >
      <ul className="partner-income-details">
        <li className="partner-income-details__item">
          <p className="partner-income-details__title">
            {_t("partner_income")}
          </p>
          <span className="partner-income-details__line"></span>
          <p className="partner-income-details__value">
            {renderCurrency(partner_income)}
          </p>
        </li>
        {(tableDetails || assured_income > 0) && (
          <li className="partner-income-details__item">
            <p className="partner-income-details__title">
              {_t("assured_income")}
            </p>
            <span className="partner-income-details__line"></span>
            <p className="partner-income-details__value">
              {renderCurrency(assured_income)}
            </p>
          </li>
        )}
        {(tableDetails || rs_income !== 0) && (
          <li className="partner-income-details__item">
            <p className="partner-income-details__title">{_t("rs_income")}</p>
            <span className="partner-income-details__line"></span>
            <p className="partner-income-details__value">
              {renderCurrency(rs_income)}
            </p>
          </li>
        )}
      </ul>

    </div>
  );
};

export default CellPartnerIncome;
