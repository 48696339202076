import React, { useState } from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { useGetTestimonialList } from "../../hooks";
import { Button, Pagination, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import TestimonialItem from "../../components/TestimonialItem";
import { getLocale } from "../../../../../../system/helpers/localstorageHelper";

const TestimonialList = () => {
  const [page, setPage] = useState(1);
  const language = getLocale();
  console.log("===", 'asd')
  const { data, isLoading } = useGetTestimonialList({ page });
  const history = useHistory();
  return (
    <MainLayout>
      <PageTemplate title={_t("testimonials")}>
        <Button
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() => history.push(APP_ROUTES.content.testimonials.create)}
        >
          {_t("create_new_testimonial")}
        </Button>
        <Spin spinning={isLoading}>
          {data && (
            <>
              {data.list?.map((item) => (
                <TestimonialItem
                  id={item.id}
                  key={item.id}
                  name={item.name}
                  title={item.translations[language]?.title}
                  content={item.translations[language]?.content}
                  link={item.translations[language]?.url}
                />
              ))}
              <Pagination
                current={data.pagination.current_page}
                defaultCurrent={1}
                total={data.pagination.total}
                onChange={setPage}
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={data.pagination.per_page || 20}
                className={"testimonial-list-pagination"}
              />
            </>
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TestimonialList;
